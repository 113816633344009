import React from 'react';
import styled, { css } from 'styled-components';
import { breakpoints, fontStyle, radius, spacing, themeLight } from '@naf/theme';
import { Text, TextVariant } from '@naf/text';
import { Checkbox } from '@naf/checkbox';
import useSelector from '../../../redux/typedHooks';
import { ProductID } from '../../../lib/ProductID';

interface ControllerProps {
  onChange: (...event: any[]) => void;
  value: ProductID;
  amount: number;
}

export const ProductIdSelect = ({ onChange, value, amount }: ControllerProps) => {
  const products = useSelector((state) => state.becomeAMember.data.products);
  const error = useSelector((state) => state.becomeAMember.errorState);
  if (error) return <Text variant={TextVariant.ArticleTextHeader}>{error.message}</Text>;

  return products && products[ProductID.Membership] ? (
    <Container outline>
      <Label>
        <CheckboxWrap>
          <StyledCheckbox
            name="with-road-assistance"
            onChange={() => onChange()}
            checked={value === ProductID.Membership}
          />
        </CheckboxWrap>
        <TextMessage>Jeg ønsker ikke veihjelp, nøkkelforsikring eller egenandelsforsikring på leiebil</TextMessage>
      </Label>
      <Message>{products[ProductID.Membership]?.productAppPrice[amount].itemTotalPrice} kr for 12 måneder</Message>
    </Container>
  ) : null;
};

const Container = styled.div<{ outline: boolean }>`
  display: inline-flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 0;
  margin-bottom: ${spacing.space16};
  margin-top: ${spacing.space40};
  max-width: 552px;

  @media (min-width: ${breakpoints.l}) {
    width: 100%;
  }

  ${({ outline }) =>
    outline &&
    css`
      padding: ${spacing.space12};
      padding-right: ${spacing.space64};
      padding-bottom: ${spacing.space16};
      border: 1px solid;
      border-radius: ${radius.s};
      border-color: ${({ theme }) => (theme.border ? theme.border.default : themeLight.border.default)};
      &:hover {
        input ~ div {
          border-color: ${({ theme }) => (theme.border ? theme.border.default : themeLight.border.default)};
        }
      }
    `}

  @media (max-width: ${breakpoints.s}) {
    flex-flow: row wrap;
    align-items: center;
    padding: ${spacing.space16} ${spacing.space12}; // fiks her
    width: calc(100% - 2 * (${spacing.space8}) - ${spacing.space12});
  }
`;

const CheckboxWrap = styled.div`
  display: inline-flex;
  width: fit-content;
  height: calc(${fontStyle.article.articleText['font-size']} * ${fontStyle.article.articleText['line-height']});
  align-items: center;

  @media (max-width: ${breakpoints.s}) {
    height: calc(${fontStyle.bodyText.bodyText['font-size']} * ${fontStyle.bodyText.bodyText['line-height']});
  }
`;

const Label = styled.label`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: fit-content;
  user-select: none;

  input {
    opacity: 0;
    position: absolute;
    z-index: -1000;
  }

  &:hover {
    cursor: pointer;
  }
`;

const TextMessage = styled.span`
  font-size: 1rem;
  line-height: 1.5rem;
`;

const Message = styled.p`
  ${fontStyle.bodyText.small};
  line-height: 1.5rem;
  margin: 0;
  margin-left: calc(${spacing.space24} + ${spacing.space8});
  color: ${({ theme }) => (theme.typography ? theme.typography.subtleText : themeLight.typography.subtleText)};
`;

const StyledCheckbox = styled(Checkbox)`
  margin: 0;
`;
